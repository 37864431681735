<template>
  <div class="content center">
    <el-form :label-position="right" label-width="180px">
      <el-form-item label="推广人姓名：">
        <el-input
          v-model="name"
          class="input-width"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="绑定推广人员手机号：">
        <div>
          <el-input
            v-model="phone"
            class="input-width"
            placeholder="请输入"
          ></el-input>
          <div class="tip">请填写微信号已绑定的手机号</div>
        </div>
      </el-form-item>
    </el-form>
    <div>
      <el-button type="primary" @click="submit">创建</el-button>
      <el-button @click="cancel">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      name: "",
      phone: "",
    };
  },

  computed: {},

  watch: {},

  methods: {
    async submit() {
      if (!this.name) {
        this.$message({
          type: "error",
          message: "你还未填写姓名，请完善信息后提交！",
        });
        return;
      }
      if (!this.phone) {
        this.$message({
          type: "error",
          message: "你还未填写手机号，请完善信息后提交！",
        });
        return;
      }
      let params = {
        name: this.name,
        phone: this.phone,
      };
      let url = "admin/seller/edit";
      const data = await this.$https.post(url, params);
      if (!data) return;
      this.$message({
        type: "success",
        message: "创建成功",
      });
      this.cancel();
    },
    cancel() {
      this.$router.push("/SellerManage/list");
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input-width {
  width: 468px;
}
.tip {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
}
</style>